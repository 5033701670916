<template>
  <div class="ui-page">
    <div class="ui-page-content">
      <div class="row">
        <div class="col-desktop-4">
          <router-link
            :to="{
              name: 'banner.banner.create',
              params: {
                bannerGroupId: $route.params.bannerGroupId,
              }
            }"
            class="button button-primary"
          >
            Создать баннер
          </router-link>
        </div>
      </div>

      <br />

      <div class="box">
        <table class="ui-table ui-table--interactive">
          <thead>
            <tr>
              <td width="200">Превью</td>
              <td>Название баннера</td>
              <td>Текст кнопки</td>
              <td>Ссылка</td>
            </tr>
          </thead>
          <tbody v-if="banners && banners.length">
            <tr
              v-for="(banner, index) in banners"
              :key="index"
              @click.prevent="
                $router.push({
                  name: 'banner.banner.edit',
                  params: { id: banner.id },
                })
              "
            >
              <td>
                <img :src="banner.attachment.file" :alt="banner.title" />
              </td>
              <td>
                {{ banner.title }}
              </td>
              <td>
                {{ banner.button_text }}
              </td>
              <td>
                {{ banner.href }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const $api = inject('$api')
const $route = useRoute()
const $router = useRouter()

const banners = ref([])
const getBanners = async () => {
  try {
    const { data: bannersListResponse } = await $api.get(
      `/admin/banner/list/${$route.params.bannerGroupId}`,
    )
    banners.value = bannersListResponse.data
  } catch (e) {
    console.log(e)
  }
}
getBanners()
</script>
