<template>
  <div class="media-gallery">
    <!-- background -->
    <div class="media-gallery__wrapper" />
    <div class="media-gallery__window">
      <div class="media-gallery__top-bar">
        <h4 class="heading_3">Медиа</h4>
        <div
          class="media-gallery__close"
          @click="$emit('close')"
        />
      </div>

      <div
        class="media-gallery__drag-area"
        v-if="isDragAreaActive"
        @drop.prevent="onDrop"
        @dragenter.prevent="setDragAreaActive"
        @dragover.prevent="setDragAreaActive"
        @dragleave.prevent="setDragAreaInactive"
      >
        <div class="media-gallery__drag-area-message" v-if="!isUploadStarted">
          Перетащите файлы сюда
        </div>
        <div class="media-gallery__upload-progress" v-if="isUploadStarted">
          <div class="media-gallery__upload-progress-bar">
            <div
              class="media-gallery__upload-progress-bar--current"
              :style="{width: fileUploadProgressCurrent / fileUploadProgressTotal * 100 + '% !important'}"
            />
          </div>
          <div class="media-gallery__upload-progress-text">
            Загружено {{ fileUploadProgressCurrent }} / {{ fileUploadProgressTotal }}
          </div>
        </div>
      </div>

      <div
        class="media-gallery__container"
        ref="mediaGalleryContainer"
        @drop.prevent="onDrop"
        @dragenter.prevent="setDragAreaActive"
      >
        <div
          :class="{
            'media-gallery__item': true,
            'media-gallery__item--selected': selectedItem?.id === item.id,
          }"
          v-for="(item, index) in galleryItems"
          :key="index"
          @click.prevent="selectElement(item)"
        >
          <img :src="item.file" :alt="item.alt" />
        </div>
      </div>

      <div class="media-gallery__bottom-bar" v-if="selectedItem">
        <div class="column">
          <span class="text small"
            >URL:
            <a :href="selectedItem.file" target="_blank">{{
              selectedItem.file
            }}</a></span
          >
          <span class="text small">Название: {{ selectedItem.alt }}</span>
        </div>

        <button
          class="button button-lg button-primary"
          type="button"
          @click="$emit('select', selectedItemId)"
        >
          Выбрать
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineExpose, useTemplateRef, inject, onMounted } from 'vue'
import { useScroll, useElementSize } from '@vueuse/core'

const $api = inject('$api')
const emit = defineEmits(['update', 'close'])

const galleryItems = ref([])
const perPage = ref(30)
const page = ref(1)
const loading = ref(false)
const isLastPage = ref(false)

const mediaGalleryContainer = useTemplateRef('mediaGalleryContainer')

const selectedItem = ref(null)

defineExpose({
  selectedItem,
})

const getGalleryItems = async () => {
  if (loading.value || isLastPage.value) return
  loading.value = true
  try {
    const { data: getGalleryItemsResponse } = await $api.get(
      `/admin/gallery/list/?page=${page.value}&per_page=${perPage.value}`,
    )
    galleryItems.value.push(...getGalleryItemsResponse.data)
    if (page.value >= getGalleryItemsResponse.meta.last_page)
      isLastPage.value = true
    page.value += 1
  } catch (e) {
    console.log(e)
  }
  loading.value = false
}
getGalleryItems()

onMounted(() => {
  mediaGalleryContainer.value.addEventListener('scroll', handleScrollGallery)
})

const handleScrollGallery = (e) => {
  let element = mediaGalleryContainer.value
  const { arrivedState } = useScroll(element, {
    offset: {
      bottom: 300,
    },
  })

  if (arrivedState.bottom) {
    getGalleryItems()
  }
}

const selectElement = (item) => {
  console.log(item)
  selectedItem.value = item
}

// drag and drop
const isDragAreaActive = ref(false)

const isUploadStarted = ref(false)

const fileUploadProgressTotal = ref(0)
const fileUploadProgressCurrent = ref(0)

const setDragAreaActive = () => {
  isDragAreaActive.value = true
}

const setDragAreaInactive = () => {
  isDragAreaActive.value = false
}

const onDrop = async (e) => {
  console.log(e.dataTransfer.files)
  
  isUploadStarted.value = true
  fileUploadProgressTotal.value = e.dataTransfer.files.length
  
  for (const file of [...e.dataTransfer.files]) {
    await uploadFile(file)
    fileUploadProgressCurrent.value += 1
  }
  
  isUploadStarted.value = false
  fileUploadProgressCurrent.value = 0
  fileUploadProgressTotal.value = 0
  
  setDragAreaInactive()
}

const uploadFile = async (file) => {
  try {
    let formData = new FormData()
    formData.append('file', file)
    const {data: uploadFileResponse} = await $api.post(`/admin/gallery/upload`, formData, {
      headers: {'Content-Type': 'multipart/form-data'}
    })
    galleryItems.value.unshift(uploadFileResponse.data);
  } catch (e) {
    console.log(e)
  }
}
</script>

<style scoped lang="scss">
@import './style';
</style>
