<template>
  <div class="ui-page">
    <div class="ui-page-content">
      <div class="row">
        <div class="col-desktop-4">
          <router-link
            :to="{
              name: 'banner.group.create',
            }"
            class="button button-primary"
          >
            Создать группу баннеров
          </router-link>
        </div>
      </div>
      
      <br />

      <div class="box">
        <table class="ui-table">
          <thead>
            <tr>
              <td>Название группы</td>
              <td>Баннеров</td>
              <td></td>
            </tr>
          </thead>
          <tbody v-if="bannerGroups && bannerGroups.length">
            <tr v-for="(item, index) in bannerGroups" :key="index">
              <td>
                <router-link
                  :to="{ name: 'banner.group.edit', params: { id: item.id } }"
                >
                  {{ item.name }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'banner.banner.list',
                    params: { bannerGroupId: item.id },
                  }"
                >
                  {{ item.banners ? item.banners.length : 0 }}
                </router-link>
              </td>
              <td>
                <div class="button-group button-group-right">
                  <button
                    type="button"
                    class="button button-primary"
                    @click.prevent="
                      $router.push({
                        name: 'banner.group.edit',
                        params: { id: item.id },
                      })
                    "
                  >
                    Редактировать группу
                  </button>
                  <button
                    type="button"
                    class="button button-primary"
                    @click.prevent="
                      $router.push({
                        name: 'banner.banner.list',
                        params: { bannerGroupId: item.id },
                      })
                    "
                  >
                    Редактировать баннеры
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue'

const $api = inject('$api')
const bannerGroups = ref([])

const getBannerGroups = async () => {
  try {
    const { data: bannerGroupsResponse } = await $api.get(
      '/admin/banner-group/list',
    )
    // console.log(bannerGroupsResponse);
    bannerGroups.value = bannerGroupsResponse.data
  } catch (e) {
    console.log(e)
  }
}

getBannerGroups()
</script>
