import * as yup from "yup";
import { setLocale } from 'yup'
import { ru } from 'yup-locales'
setLocale(ru)

const categoryValidationSchema = yup.object({
    slug: yup.string().max(511).required(),
    title: yup.string().max(255).required(),
    parent_id: yup.number().min(0).nullable().isValidSync('null'),
    display_home: yup.boolean(),
    display_footer: yup.boolean(),
    is_published: yup.boolean(),
})

export default categoryValidationSchema;
