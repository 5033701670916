import axios from 'axios'
import { useUserStore } from '@/stores/user'
import { ref } from 'vue'

const createAxiosInstance = () => {
  const userStore = useUserStore()
  const headers = ref({
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    token: null,
  })

  if (userStore.isAuthorized) {
    headers.value.token = userStore.session.token
  }

  return axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL + '/api',
    headers: headers.value,
    defaults: {
      withCredentials: true,
    },
  })
}

export default createAxiosInstance
