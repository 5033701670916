import { defineStore } from 'pinia'
import * as core from '@vueuse/core';
import router from "../router/index";

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      user: core.useLocalStorage('userStore/user', {
        email: null,
        name: null,
        avatar: null,
      }),

      role: core.useLocalStorage('userStore/role', {
        slug: null,
        name: null,
      }),

      session: core.useLocalStorage('userStore/session', {
        token: null,
        browser: null,
      }),

      isAuthorized: core.useLocalStorage('userStore/isAuthorized', false),
    }
  },
  actions: {
    logout() {
      this.user = {
        email: null,
        name: null,
        avatar: null,
      }
      this.role = {
        slug: null,
        name: null
      }
      this.auth = {
        token: null,
        browser: null
      }
      this.isAuthorized = false
      router.push({name: 'home'})
    }
  }
})
