import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from "pinia";
import Vue3Toastify, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import router from './router';

//import '@/scss/main.scss';


const app = createApp(App);

const pinia = createPinia()
app.use(pinia)
app.use(Vue3Toastify, {
    autoClose: 3000,
});

// axios instance
import createAxiosInstance from "@/axios/createAxiosInstance";
// const createAxiosIstanceGlob = () => {
//     createAxiosInstance()
// }

// const createAxiosIstanceGlob = () => {
//     const axiosInstance = createAxiosInstance()
//     app.config.globalProperties.$api = axiosInstance();
//
// }
//
// app.config.globalProperties.$createAxiosInstanceGlob = createAxiosIstanceGlob
// app.provide('$createAxiosInstanceGlob', createAxiosIstanceGlob);

app.provide('$api', createAxiosInstance());
createAxiosInstance()
// createAxiosIstanceGlob()

// toastify
app.config.globalProperties.$toast = toast
app.provide('$toast', toast)

app.use(router)
app.mount('#app')
