<template>
  <div class="ui-page">
    <div class="ui-page-content">
      <div class="row">
        <div class="col-desktop-4">
          <router-link
            :to="{
              name: 'promo-code.create',
            }"
            class="button button-primary"
          >
            Создать промо-код
          </router-link>
        </div>
      </div>

      <br />

      <div class="box">
        <table class="ui-table ui-table--interactive">
          <thead>
            <tr>
              <td>Название</td>
              <td>Описание</td>
              <td>Код</td>
              <td>Скидка</td>
              <td>Начало</td>
              <td>Окончание</td>
              <td>Статус</td>
            </tr>
          </thead>
          <tbody v-if="promocodes && promocodes.length">
            <tr
              v-for="(item, index) of promocodes"
              :key="index"
              @click.prevent="
                $router.push({
                  name: 'promo-code.edit',
                  params: { id: item.id },
                })
              "
            >
              <td>
                {{ item.title }}
              </td>
              <td>{{ item.description }}</td>
              <td>{{ item.code }}</td>
              <td>
                {{
                  item.discount_percent
                    ? item.discount_percent + '%'
                    : item.discount_fixed + 'Р'
                }}
              </td>
              <td>{{ item.start_at }}</td>
              <td>{{ item.end_at }}</td>
              <td>
                <span
                  :class="{
                    'badge': true,
                    'badge-success': item.is_published,
                    'badge-danger': !item.is_published,
                  }"
                >
                  {{ item.is_published ? 'Опубликован' : 'Не опубликован' }}
                </span>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="7">
                <span class="text">Вы еще не создали ни одного промо кода</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import { useRouter } from 'vue-router'

const $api = inject('$api')
const $router = useRouter()

const promocodes = ref([])
const getPromocodes = async () => {
  try {
    const { data: getPromocodeResponse } = await $api.get(
      '/admin/promo-code/list',
    )
    console.log(getPromocodeResponse)
    promocodes.value = getPromocodeResponse.data
  } catch (e) {
    console.error(e)
  }
}
getPromocodes()
</script>
