<template>
  <div class="ui-page">
    <div class="ui-page-content">
      <div class="row">
        <div class="col-desktop-4">
          <router-link
            :to="{
              name: 'product.create',
            }"
            class="button button-primary"
          >
            Создать продукт
          </router-link>
        </div>
      </div>

      <br />

      <div class="box">
        <table class="ui-table ui-table--interactive">
          <thead>
            <tr>
              <td>Изображение</td>
              <td>Название</td>
              <td>Вариации</td>
              <td>Категории</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(product, index) in products"
              :key="product.id"
              @click.prevent="$router.push({ name: 'product.edit', params: { id: product.id } })"
            >
              <td width="96">
                <slot
                  v-if="product.attachments && product.attachments.length > 0"
                >
                  <img
                    :src="product.attachments[0].file"
                    :alt="product.attachments[0].alt"
                  />
                </slot>
                <slot v-else>
                  <img
                    src="@/assets/img/no-photo.svg"
                    alt="Нет фото"
                  />
                </slot>
              </td>
              <td>
                {{ product.name }}
              </td>
              <td>
                {{ product.variants.length }} вариаций
              </td>
              <td>
                <div
                  class="column"
                  v-if="product.categories && product.categories.length"
                >
                  <span
                    class="text"
                    v-for="singleCategory in product.categories"
                    :key="singleCategory.id"
                  >
                    {{ singleCategory.title }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import { useRouter } from "vue-router";

const $api = inject('$api')
const $router = useRouter()

const products = ref([])
const getProducts = async () => {
  try {
    const { data: getCategoriesResponse } = await $api.get(
      '/admin/product/list',
    )
    products.value = getCategoriesResponse.data
    console.log(getCategoriesResponse.data)
  } catch (e) {
    console.error(e)
  }
}
getProducts()
</script>
