<template>
  <div class="ui-page">
    <div class="ui-page-content" v-if="promoCode">
      <Form
        @submit="updatePromoCode"
        :initial-values="promoCode"
        :validation-schema="promoCodeValidationSchema"
        v-slot="{ errors, values }"
      >
        <div class="row">
          <div class="col-desktop-9 col-tablet-12">
            <div class="box">
              <h1 class="heading_5 box-heading">Редактирование промо-кода</h1>

              <div class="column">
                <div class="form-group">
                  <label for="title">Название</label>
                  <Field
                    id="title"
                    name="title"
                    type="text"
                    :class="{
                      input: true,
                      'input--error': errors['title'],
                    }"
                    placeholder="Название"
                  />
                  <ErrorMessage name="title" class="error" />
                </div>
                <div class="form-group">
                  <label for="description">Описание</label>
                  <Field
                    id="description"
                    name="description"
                    type="text"
                    :class="{
                      input: true,
                      'input--error': errors['description'],
                    }"
                    placeholder="Описание"
                  />
                  <ErrorMessage name="description" class="error" />
                </div>
                <div class="form-group">
                  <label for="code">Промо-код</label>
                  <Field
                    id="code"
                    name="code"
                    type="text"
                    :class="{
                      input: true,
                      'input--error': errors['code'],
                    }"
                    placeholder="Промо-код"
                  />
                  <ErrorMessage name="code" class="error" />
                </div>

                <div class="row">
                  <div class="col-desktop-6">
                    <div class="form-group">
                      <label for="discount_percent">Процент скидки</label>
                      <Field
                        id="discount_percent"
                        name="discount_percent"
                        type="text"
                        :class="{
                          input: true,
                          'input--error': errors['discount_percent'],
                        }"
                        placeholder="Процент скидки"
                      />
                      <ErrorMessage name="discount_percent" class="error" />
                    </div>
                  </div>
                  <div class="col-desktop-6">
                    <div class="form-group">
                      <label for="discount_fixed">Фиксированная скидка</label>
                      <Field
                        id="discount_fixed"
                        name="discount_fixed"
                        type="text"
                        :class="{
                          input: true,
                          'input--error': errors['discount_fixed'],
                        }"
                        placeholder="Фиксированная скидка"
                      />
                      <ErrorMessage name="discount_fixed" class="error" />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="promo_code_products">
                    Использовать только на продукты
                  </label>
                  <div class="multiselect-container">
                    <div class="multiselect">
                      <div
                        class="multiselect-placeholder"
                        @click.prevent="toggleMultiselectProducts"
                      >
                        Нажмите, чтобы добавить продукты
                      </div>
                      <!-- выбрано -->
                      <div
                        class="multiselect-selected"
                        v-if="
                          multiselectProducts.selected &&
                          multiselectProducts.selected.length
                        "
                      >
                        <span
                          class="badge badge-small badge-success"
                          v-for="(
                            product, index
                          ) in multiselectProducts.selected"
                          :key="index"
                        >
                          {{ product.name }}
                          <span class="caption" v-if="product.article_number"
                            >#{{ product.article_number }}</span
                          >
                        </span>
                      </div>

                      <!-- полный список -->
                      <div
                        class="multiselect-list"
                        v-if="multiselectProducts.isOpened"
                      >
                        <div class="multiselect-list-search">
                          <input
                            type="text"
                            class="input input-sm"
                            placeholder="Начните вводить название продукта"
                            v-model="multiselectProducts.search.text"
                            @change="searchMultiselectProducts"
                          />
                        </div>
                        <!-- результаты поиска -->
                        <div
                          class="multiselect-list-items"
                          v-if="
                            multiselectProducts.search.results &&
                            multiselectProducts.search.results.length
                          "
                        >
                          <div
                            v-for="(product, index) in multiselectProducts
                              .search.results"
                            :key="index"
                            :class="{
                              'multiselect-list-item': true,
                              'multiselect-list-item-product': true,
                              'multiselect-list-item--selected':
                                multiselectProducts.selected.indexOf(product) >
                                -1,
                            }"
                            @click.prevent="checkMultiselectProduct(product)"
                          >
                            <div>
                              <slot
                                v-if="
                                  product.attachments &&
                                  product.attachments.length
                                "
                              >
                                <img
                                  :src="product.attachments[0].file"
                                  :alt="product.attachments[0].alt"
                                />
                              </slot>
                              <slot v-else>
                                <img
                                  src="@/assets/img/no-photo.svg"
                                  alt="Нет фонового изображения"
                                />
                              </slot>
                            </div>
                            <div>
                              {{ product.name }}
                              <span
                                class="caption"
                                v-if="product.article_number"
                                >#{{ product.article_number }}</span
                              >
                            </div>
                          </div>
                        </div>

                        <!-- все элементы -->
                        <div class="multiselect-list-items" v-else>
                          <div
                            v-for="(
                              product, index
                            ) in multiselectProducts.items"
                            :key="index"
                            :class="{
                              'multiselect-list-item': true,
                              'multiselect-list-item-product': true,
                              'multiselect-list-item--selected':
                                multiselectProducts.selected.indexOf(product) >
                                -1,
                            }"
                            @click.prevent="checkMultiselectProduct(product)"
                          >
                            <div>
                              <slot
                                v-if="
                                  product.attachments &&
                                  product.attachments.length
                                "
                              >
                                <img
                                  :src="product.attachments[0].file"
                                  :alt="product.attachments[0].alt"
                                />
                              </slot>
                              <slot v-else>
                                <img
                                  src="@/assets/img/no-photo.svg"
                                  alt="Нет фонового изображения"
                                />
                              </slot>
                            </div>
                            <div>
                              {{ product.name }}
                              <span
                                class="caption"
                                v-if="product.article_number"
                                >#{{ product.article_number }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="promo_code_categories">
                    Использовать для продуктов в категории
                  </label>
                  <div class="multiselect-container">
                    <div class="multiselect">
                      <div
                        class="multiselect-placeholder"
                        @click.prevent="toggleMultiselectCategories"
                      >
                        Нажмите, чтобы добавить категории
                      </div>

                      <!-- выбрано -->
                      <div
                        class="multiselect-selected"
                        v-if="
                          multiselectCategories.selected &&
                          multiselectCategories.selected.length
                        "
                      >
                        <span
                          class="badge badge-small badge-success"
                          v-for="(
                            category, index
                          ) in multiselectCategories.selected"
                          :key="index"
                        >
                          {{ category.title }}
                        </span>
                      </div>

                      <!-- полный список -->
                      <div
                        class="multiselect-list"
                        v-if="multiselectCategories.isOpened"
                      >
                        <div class="multiselect-list-search">
                          <input
                            type="text"
                            class="input input-sm"
                            placeholder="Начните вводить название категории"
                            v-model="multiselectCategories.search.text"
                            @change="searchMultiselectCategory"
                          />
                        </div>
                        <!-- результаты поиска -->
                        <div
                          class="multiselect-list-items"
                          v-if="
                            multiselectCategories.search.results &&
                            multiselectCategories.search.results.length
                          "
                        >
                          <div
                            v-for="(category, index) in multiselectCategories
                              .search.results"
                            :key="index"
                            :class="{
                              'multiselect-list-item': true,
                              'multiselect-list-item-product': true,
                              'multiselect-list-item--selected':
                                multiselectCategories.selected.indexOf(
                                  category,
                                ) > -1,
                            }"
                            @click.prevent="checkMultiselectCategory(category)"
                          >
                            <div>
                              <slot
                                v-if="
                                  category.attachments &&
                                  category.attachments.length
                                "
                              >
                                <img
                                  :src="category.attachments[0].file"
                                  :alt="category.attachments[0].alt"
                                />
                              </slot>
                              <slot v-else>
                                <img
                                  src="@/assets/img/no-photo.svg"
                                  alt="Нет фонового изображения"
                                />
                              </slot>
                            </div>
                            <div>
                              {{ category.title }}
                            </div>
                          </div>
                        </div>
                        
                        <!-- все элементы -->
                        <div class="multiselect-list-items" v-else>
                          <div
                            v-for="(
                            category, index
                          ) in multiselectCategories.items"
                            :key="index"
                            :class="{
                            'multiselect-list-item': true,
                            'multiselect-list-item-product': true,
                            'multiselect-list-item--selected':
                              multiselectCategories.selected.indexOf(category) >
                              -1,
                          }"
                            @click.prevent="checkMultiselectCategory(category)"
                          >
                            <div>
                              <slot
                                v-if="
                                category.attachments &&
                                category.attachments.length
                              "
                              >
                                <img
                                  :src="category.attachments[0].file"
                                  :alt="category.attachments[0].alt"
                                />
                              </slot>
                              <slot v-else>
                                <img
                                  src="@/assets/img/no-photo.svg"
                                  alt="Нет фонового изображения"
                                />
                              </slot>
                            </div>
                            <div>
                              {{ category.title }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-desktop-3 col-tablet-12">
            <div class="box">
              <div class="column">
                <div class="form-group">
                  <div class="checkbox-container">
                    <Field
                      type="checkbox"
                      id="is_published"
                      name="is_published"
                      :value="true"
                      :checked="values.is_published"
                    />
                    <label for="is_published"> Опубликован </label>
                  </div>
                </div>

                <button class="button button-primary" type="submit">
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup>
// validation
import promoCodeValidationSchema from '../validation-schema'
import { ErrorMessage, Field, Form } from 'vee-validate'

import { ref, inject, onMounted } from 'vue'
import { useRoute } from 'vue-router'

const $api = inject('$api')
const $toast = inject('$toast')
const $route = useRoute()

const promoCode = ref(null)
const getPromoCode = async () => {
  const { data: getPromoCodeResponse } = await $api.get(
    `/admin/promo-code/get/${$route.params.id}`,
  )
  promoCode.value = getPromoCodeResponse
}
getPromoCode()

// products
//const productList = ref([])
const getProducts = async () => {
  try {
    const { data: getProductsResponse } = await $api.get('/admin/product/list')
    //productList.value = getProductsResponse.data
    multiselectProducts.value.items = getProductsResponse.data
  } catch (e) {
    console.error(e)
  }
}

// categories
//const productCategoryList = ref([])
const getCategories = async () => {
  try {
    const { data: getCategoriesResponse } = await $api.get(
      '/admin/category/list',
    )
    //productCategoryList.value = getCategoriesResponse.data
    multiselectCategories.value.items = getCategoriesResponse.data
  } catch (e) {
    console.log(e)
  }
}

const updatePromoCode = async (formData) => {
  try {
    const { data: updatePromoCodeResponse } = await $api.put(
      `/admin/promo-code/update/${$route.params.id}`,
      formData,
    )
    promoCode.value = updatePromoCodeResponse
    $toast.success('Изменения сохранены!')
  } catch (e) {
    console.error(e)
  }
}

onMounted(() => {
  Promise.all([getProducts(), getCategories()])
})

// multiselect products
const multiselectProducts = ref({
  search: {
    text: '',
    results: [],
    isLoading: false,
  },
  items: [],
  selected: [],
  isOpened: false,
})
const toggleMultiselectProducts = () =>
  (multiselectProducts.value.isOpened = !multiselectProducts.value.isOpened)
const searchMultiselectProducts = async () => {
  // if search field is empty
  if (
    !multiselectProducts.value.search.text ||
    !multiselectProducts.value.search.text.length
  )
    return

  // if loading
  if (multiselectProducts.value.search.isLoading) return
  multiselectProducts.value.search.isLoading = true

  try {
    const { data: searchMultiselectProductsResponse } = await $api.get(
      `/admin/product/search/?search=${multiselectProducts.value.search.text}`,
    )
    multiselectProducts.value.search.results =
      searchMultiselectProductsResponse.data
  } catch (e) {
    console.error(e)
  }

  multiselectProducts.value.search.isLoading = false
}
const checkMultiselectProduct = (product) => {
  if (multiselectProducts.value.selected.indexOf(product) === -1) {
    multiselectProducts.value.selected.push(product)
  } else {
    multiselectProducts.value.selected.splice(multiselectProducts.value.selected.indexOf(product), 1)
  }
  
  multiselectProducts.value.search.results = []
  multiselectProducts.value.search.text = ''
  multiselectProducts.value.isOpened = false
}

// multiselect products
const multiselectCategories = ref({
  search: {
    text: '',
    results: [],
    isLoading: false,
  },
  items: [],
  selected: [],
  isOpened: false,
})
const toggleMultiselectCategories = () => multiselectCategories.value.isOpened = !multiselectCategories.value.isOpened

const searchMultiselectCategory = async () => {
  // if search field is empty
  if (
    !multiselectCategories.value.search.text ||
    !multiselectCategories.value.search.text.length
  )
    return

  // if loading
  if (multiselectCategories.value.search.isLoading) return
  multiselectCategories.value.search.isLoading = true

  try {
    const { data: searchMultiselectCategoriesResponse } = await $api.get(
      `/admin/category/search/?search=${multiselectCategories.value.search.text}`,
    )
    multiselectCategories.value.search.results =
      searchMultiselectCategoriesResponse.data
  } catch (e) {
    console.error(e)
  }

  multiselectCategories.value.search.isLoading = false
}
const checkMultiselectCategory = (category) => {
  if(multiselectCategories.value.selected.indexOf(category) === -1) {
    multiselectCategories.value.selected.push(category)
  } else {
    multiselectCategories.value.selected.splice(multiselectCategories.value.selected.indexOf(category), 1)
  }
  
  multiselectCategories.value.search.results = []
  multiselectCategories.value.search.text = ''
  multiselectCategories.value.isOpened = false
}
</script>
