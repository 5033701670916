<template>
  <div v-if="pageIsReady">
    <form class="ui-form" @submit.prevent="updateMoyskladSettings">
      <div class="ui-form-group">
        <label for="moysklad_login">Логин в системе</label>
        <input
          type="text"
          v-model="settings.login"
          placeholder="Логин"
          id="moysklad_login"
          class="ui-input"
        />
      </div>
      <div class="ui-form-group">
        <label for="moysklad_password">Пароль</label>
        <input
          type="text"
          v-model="settings.password"
          placeholder="Пароль"
          id="moysklad_password"
          class="ui-input"
        />
      </div>
      <div>
        <h2>Цены для пользователей</h2>
        <div v-for="(i, item) in settings.prices">
          <div class="ui-form-group">
            <label :for="`shop_role_id_${i}`">Роль</label>
            <select
              class="ui-input"
              :id="`shop_role_id_${i}`"
              v-model="item.shop_role_id"
            >
              <option
                v-for="user_role in userRoles"
                :key="user_role.id"
                :value="user_role.id"
                :selected="user_role.id === item.shop_role_id"
              >
                {{ user_role.name }}
              </option>
            </select>
          </div>
          <div class="ui-form-group">
            <label :for="`regular_price_type_id_${i}`">Стандартная цена</label>
            <select
              class="ui-form-group"
              :id="`regular_price_type_id_${i}`"
              v-model="item.regular_price_type_id"
            >
              <option
                v-for="price_type in shopPriceTypes"
                :key="price_type.id"
                :value="price_type.id"
              >
                {{ price_type.name }}
              </option>
            </select>
          </div>
          <div class="ui-form-group">
            <label :for="`discount_price_type_id_${i}`">Цена со скидкой</label>
            <select
              class="ui-form-group"
              :id="`discount_price_type_id_${i}`"
              v-model="item.discount_price_type_id"
            >
              <option
                v-for="price_type in shopPriceTypes"
                :key="price_type.id"
                :value="price_type.id"
              >
                {{ price_type.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div>
        <h2>Статусы заказов</h2>
        <div v-for="(i, item) in settings.order_statuses">
          <div class="ui-form-group">
            <label :for="`shop_order_status_${i}`">Статус магазина</label>
            <select
              class="ui-input"
              v-model="item.shop_oder_status"
            >
              <option
                v-for="order_status in shopOrderStatuses"
              >
              
              </option>
            </select>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue'

const $toast = inject('$toast')

const pageIsReady = ref(false)
const loading = ref(false)

const settings = ref({
  login: null,
  password: null,
  prices: [
    {
      shop_role_id: null,
      regular_price_type_id: null,
      discount_price_type_id: null,
    },
  ],
  order_statuses: [
    {
      shop_oder_status: null,
      moysklad_order_status: null,
    },
  ],
})

const userRoles = ref([])
const shopPriceTypes = ref([])
const shopOrderStatuses = ref([])
const moyskladOrderStatuses = ref([])
// const moyskladPriceTypes = ref([])

const getMoyskladSettings = async () => {
  try {
    const { data: moyskladSettingsResponse } = await $api.get(
      `/settings/moysklad/get`,
    )
    settings.value = moyskladSettingsResponse.value
  } catch (e) {
    $toast.error('Не удалось получить настройки системы Мой Склад')
  }
}

const getUserRoles = async () => {
  try {
    const { data: userRolesResponse } = await $api.get(`/user-role/list`)
    userRoles.value = userRolesResponse.value
  } catch (e) {
    $toast.error('Не удалось получить список ролей для пользователей')
  }
}

const getPriceTypes = async () => {
  try {
    const { data: priceTypeResponse } = await $api.get('/price-type/list')
    shopPriceTypes.value = priceTypeResponse.value
  } catch (e) {
    $toast.error('Не удалось получить список типов цен магазина')
  }
}

const getShopOrderStatuses = async () => {
  try {
    const { data: shopOrderStatusesResponse } = await $api.get(
      '/order-statuses/list'
    )
    shopOrderStatuses.value = shopOrderStatusesResponse.value
  } catch (e) {
    $toast.error('Не удалось получить список статусов заказа магазина')
  }
}

const getMoyskladOrderStatuses = async () => {
  try {
    const { data: moyskladOrderStatusesResponse } = await $api.get(
      '/moysklad-order-statuses/list'
    )
    moyskladOrderStatuses.value = moyskladOrderStatusesResponse.value
  } catch (e) {
    $toast.error('Не удалось получить список статусов заказ из системы Мой Склад')
  }
}

const updateMoyskladSettings = async () => {
  if (loading.value) return
  loading.value = true

  try {
    const { data: updateMoyskladSettingsResponse } = await $api.put(
      '/settings/moysklad/update',
      settings.value,
    )
    settings.value = updateMoyskladSettingsResponse.value
  } catch (e) {
    $toast.error('Не удалось сохранить настройки для системы Мой Склад')
  }

  loading.value = false
}

onMounted(() => {
  Promise.all([
    getMoyskladSettings,
    getUserRoles,
    getPriceTypes,
    getShopOrderStatuses,
    getMoyskladOrderStatuses
  ]).then(() => {
    pageIsReady.value = true
  })
})
</script>
