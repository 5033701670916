<template>
  <div class="ui-page">
    <div class="ui-page-content">
      <div class="row">
        <div class="col-desktop-4">
          <router-link
            :to="{
              name: 'showcase.create',
            }"
            class="button button-primary"
          >
            Создать витрину
          </router-link>
        </div>
      </div>

      <br />

      <div class="box">
        <table class="ui-table ui-table--interactive">
          <thead>
            <tr>
              <td>Название</td>
              <td>Slug</td>
              <td>Продукты</td>
            </tr>
          </thead>
          <tbody v-if="showcases && showcases.length">
            <tr
              v-for="(showcase, index) in showcases"
              :key="index"
              @click.prevent="
                $router.push({
                  name: 'showcase.edit',
                  params: { id: showcase.id },
                })
              "
            >
              <td>
                {{ showcase.name }}
              </td>
              <td>{{ showcase.slug }}</td>
              <td>
                <slot v-if="showcase.products && showcase.products.length">
                  {{ showcase.products.length }} продуктов
                </slot>
                <slot v-else> 0 продуктов</slot>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import { useRouter } from 'vue-router'

const $router = useRouter()
const $api = inject('$api')
const showcases = ref([])
const getShowcases = async () => {
  try {
    const { data: getShowcaseResponse } = await $api.get('/admin/showcase/list')
    showcases.value = getShowcaseResponse.data
    console.log(showcases.value)
  } catch (e) {
    console.log(e)
  }
}
getShowcases()
</script>
