<template>
  <div class="ui-page">
    <div class="ui-page-content">
      <div class="box">
        <h1 class="heading_5 box-heading">Создание группы баннеров</h1>
        
        <Form
          class="column"
          :validation-schema="bannerGroupValidationSchema"
          v-slot="{ errors, values }"
          @submit="createBannerGroup"
        >
          <div class="form-group">
            <label class="label" for="name">Название</label>
            <Field
              id="name"
              name="name"
              type="text"
              placeholder="Название группы"
              :class="{
                input: true,
                'is-invalid': errors['name'],
              }"
            />
            <ErrorMessage name="name" class="error" />
          </div>
          
          <div class="form-group">
            <label class="label" for="slug">Slug</label>
            <Field
              id="slug"
              name="slug"
              type="text"
              placeholder="Описание"
              :class="{
                input: true,
                'is-invalid': errors['slug'],
              }"
            />
            <ErrorMessage name="slug" class="error" />
          </div>
          
          <div class="form-group">
            <label class="label" for="description">Описание</label>
            <Field
              id="description"
              name="description"
              type="text"
              placeholder="Описание"
              :class="{
                input: true,
                'is-invalid': errors['description'],
              }"
            />
            <ErrorMessage name="description" class="error" />
          </div>
          
          <div class="form-group">
            <button
              class="button button-primary"
              type="submit"
            >
              Сохранить
            </button>
          </div>
        
        </Form>
      </div>
    </div>
  </div>
</template>

<script setup>
// validation
import { Form, Field, ErrorMessage } from 'vee-validate'
import bannerGroupValidationSchema from "../validation-schema";

// other
import { ref, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const $api = inject('$api')
const $toast = inject('$toast')
const $router = useRouter()

// update banner group data
const createBannerGroup = async (data) => {
  try {
    const {data: updateBannerGroupDataResponse} = await $api.post(
      `/admin/banner-group/create`,
      data
    )
    
    $toast.success('Группа создана!');
    await $router.push({
      name: 'banner.group.list'
    })
  } catch (e) {
    console.error(e)
  }
}
</script>
