import * as yup from "yup";
import { setLocale } from 'yup'
import { ru } from 'yup-locales'
setLocale(ru)

const promoCodeValidationSchema = yup.object({
    title: yup.string().max(255).required(),
    description: yup.string().max(512).required(),
    code: yup.string().max(128).required(),
    discount_percent: yup.string().min(0).max(100).required(),
    discount_fixed: yup.string().min(0).required(),
})

export default promoCodeValidationSchema;
