import * as yup from "yup";
import { setLocale } from 'yup'
import { ru } from 'yup-locales'
setLocale(ru)

const bannerValidationSchema = yup.object({
    title: yup.string().max(511).required(),
    description: yup.string().max(1023).required(),
    href: yup.string().max(1023),
    button_text: yup.string().max(256)
})

export default bannerValidationSchema;
