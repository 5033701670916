<template>
  <div class="ui-page">
    <div class="ui-page-content">
      <div class="box" v-if="promoCode">
        <h1 class="heading_5 box-heading">Редактирование промо-кода</h1>

        <Form
          @submit="createPromoCode"
          :initial-values="promoCode"
          :validation-schema="promoCodeValidationSchema"
          v-slot="{ errors, values }"
        >
          <div class="column">
            <div class="form-group">
              <label for="title">Название</label>
              <Field
                id="title"
                name="title"
                type="text"
                :class="{
                  input: true,
                  'input--error': errors['title'],
                }"
                placeholder="Название"
              />
              <ErrorMessage name="title" class="error" />
            </div>
            <div class="form-group">
              <label for="description">Описание</label>
              <Field
                id="description"
                name="description"
                type="text"
                :class="{
                  input: true,
                  'input--error': errors['description'],
                }"
                placeholder="Описание"
              />
              <ErrorMessage name="description" class="error" />
            </div>
            <div class="form-group">
              <label for="code">Промо-код</label>
              <Field
                id="code"
                name="code"
                type="text"
                :class="{
                  input: true,
                  'input--error': errors['code'],
                }"
                placeholder="Промо-код"
              />
              <ErrorMessage name="code" class="error" />
            </div>

            <div class="row">
              <div class="col-desktop-6">
                <div class="form-group">
                  <label for="discount_percent">Процент скидки</label>
                  <Field
                    id="discount_percent"
                    name="discount_percent"
                    type="text"
                    :class="{
                      input: true,
                      'input--error': errors['discount_percent'],
                    }"
                    placeholder="Процент скидки"
                  />
                  <ErrorMessage name="discount_percent" class="error" />
                </div>
              </div>
              <div class="col-desktop-6">
                <div class="form-group">
                  <label for="discount_fixed">Фиксированная скидка</label>
                  <Field
                    id="discount_fixed"
                    name="discount_fixed"
                    type="text"
                    :class="{
                      input: true,
                      'input--error': errors['discount_fixed'],
                    }"
                    placeholder="Фиксированная скидка"
                  />
                  <ErrorMessage name="discount_fixed" class="error" />
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="checkbox">
                <Field
                  type="checkbox"
                  id="is_published"
                  name="is_published"
                  :value="true"
                  :checked="values.is_published"
                />
                <label for="is_published"> Опубликован </label>
              </div>
            </div>

            <button class="button button-primary" type="submit">
              Сохранить
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script setup>
// validation
import promoCodeValidationSchema from '../validation-schema'
import { ErrorMessage, Field, Form } from 'vee-validate'

import { ref, inject } from 'vue'
import { useRoute } from 'vue-router'

const $api = inject('$api')
const $toast = inject('$toast')
const $route = useRoute()

const promoCode = ref({
  title: '',
  description: '',
  code: '',
  discount_percent: '',
  discount_fixed: '',
  is_published: true,
})

const createPromoCode = async (formData) => {
  try {
    const { data: createPromoCodeResponse } = await $api.post(
      '/admin/promo-code/create',
      formData,
    )
    promoCode.value = createPromoCodeResponse
    $toast.success('Промо-код создан!')
  } catch (e) {
    console.error(e)
  }
}
</script>
