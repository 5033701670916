<template>
  <div class="ui-page">
    <div
      class="ui-page-content"
      v-if="categoryData && categoriesParentData && categoriesParentData.length"
    >
      <Form
        class="row"
        :validation-schema="categoryValidationSchema"
        :initial-values="categoryData"
        @submit="createCategory"
        v-slot="{ errors, values }"
      >
        <div class="col-desktop-9">
          <div class="box">
            <h1 class="heading_5 box-heading">Редактирование категории</h1>
            
            <div class="column">
              <div class="form-group">
                <label for="title">Название категории</label>
                <Field
                  id="title"
                  name="title"
                  type="text"
                  class="input"
                  placeholder="Название категории"
                />
                <ErrorMessage name="title" class="error" />
              </div>
              
              <div class="form-group">
                <label for="slug">Slug</label>
                <Field
                  id="slug"
                  name="slug"
                  type="text"
                  class="input"
                  placeholder="Slug"
                />
                <ErrorMessage name="slug" class="error" />
              </div>
              
              <div class="form-group">
                <label for="parent_id">Родительская категория</label>
                <Field
                  as="select"
                  id="parent_id"
                  name="parent_id"
                  class="input"
                  placeholder="Slug"
                >
                  <option
                    value
                  >
                    Корневая
                  </option>
                  <option
                    v-for="category in categoriesParentData"
                    :key="category.id"
                    :value="category.id"
                    :selected="category.id === categoryData.parent_id"
                  >
                    {{ category.title }}
                  </option>
                </Field>
                <ErrorMessage name="parent_id" class="error" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-desktop-3">
          <div class="box">
            <div class="column">
              <div class="form-group">
                <div class="checkbox-container">
                  <Field
                    id="display_home"
                    name="display_home"
                    type="checkbox"
                    :value="true"
                    :checked="values.display_home"
                  />
                  <label for="display_home">Отображать на главной</label>
                </div>
                <ErrorMessage name="display_home" class="error" />
              </div>
              
              <div class="form-group">
                <div class="checkbox-container">
                  <Field
                    id="display_footer"
                    name="display_footer"
                    type="checkbox"
                    :value="true"
                    :checked="values.display_footer"
                  />
                  <label for="display_footer">Отображать в футере</label>
                </div>
                <ErrorMessage name="display_footer" class="error" />
              </div>
              
              <div class="form-group">
                <div class="checkbox-container">
                  <Field
                    id="is_published"
                    name="is_published"
                    type="checkbox"
                    :value="true"
                    :checked="values.is_published"
                  />
                  <label for="is_published">Опубликована</label>
                </div>
                <ErrorMessage name="is_published" class="error" />
              </div>
              
              <div class="form-group">
                <button type="submit" class="button button-primary">
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script setup>
import categoryValidationSchema from '../validation-schema'

import { ref, inject } from 'vue'
import { ErrorMessage, Field, Form } from 'vee-validate'

const $api = inject('$api')
const $toast = inject('$toast')

const categoryData = ref({
  slug: null,
  title: null,
  parent_id: null,
  display_home: false,
  display_footer: false,
  is_published: false,
  attachment_id: null,
})

const categoriesParentData = ref(null)
const getCategoriesParent = async () => {
  try {
    const { data: getCategoriesParentResponse } = await $api.get(
      `/admin/category/list?with_parent=0`,
    )
    categoriesParentData.value = getCategoriesParentResponse.data
  } catch (e) {
    console.log(e)
  }
}
getCategoriesParent()

const createCategory = async (formData) => {
  try {
    const { data: createCategoryResponse } = await $api.post(
      `/admin/category/create`,
      formData,
    )
    categoryData.value = createCategoryResponse.data
    $toast.success('Категория создана')
  } catch (e) {
    $toast.error(e)
    console.log(e)
  }
}
</script>
