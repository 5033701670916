<template>
  <div class="ui-page">
    <div class="ui-page-content">
      <div class="row">
        <div class="col-desktop-9 col-tablet-12">
          <div class="box" v-if="bannerData">
            <h1 class="heading_5 box-heading">Редактирование баннера</h1>

            <Form
              class="column"
              :validation-schema="bannerValidationSchema"
              :initial-values="bannerData"
              @submit="updateBannerData"
            >
              <div class="form-group">
                <label for="title">Название баннера</label>
                <Field
                  id="title"
                  name="title"
                  type="text"
                  placeholder="Название баннера"
                  class="input"
                />
                <ErrorMessage name="title" class="error" />
              </div>

              <div class="form-group">
                <label for="description">Описание</label>
                <Field
                  id="description"
                  name="description"
                  type="text"
                  placeholder="Описание"
                  class="input"
                />
                <ErrorMessage name="description" class="error" />
              </div>

              <div class="form-group">
                <label for="button_text">Текст кнопки</label>
                <Field
                  id="button_text"
                  name="button_text"
                  type="text"
                  placeholder="Текст кнопки"
                  class="input"
                />
                <ErrorMessage name="button_text" class="error" />
              </div>

              <div class="form-group">
                <label for="href">Ссылка</label>
                <Field
                  id="href"
                  name="href"
                  type="text"
                  placeholder="Ссылка"
                  class="input"
                />
                <ErrorMessage name="href" class="error" />
              </div>

              <div class="form-group">
                <button type="submit" class="button button-primary">
                  Сохранить
                </button>
              </div>
            </Form>
          </div>
        </div>
        <div class="col-desktop-3 col-tablet-12">
          <div class="box" v-if="attachment">
            <h2 class="heading_5 box-heading">Вложение</h2>
            

            <div class="attachment-upload">
              <slot v-if="attachment.file">
                <div
                  class="attachment-upload__delete"
                  @click.prevent="removeAttachment"
                />
                <img :src="attachment.file" :alt="attachment.alt" />

                <div class="form-group">
                  <label for="attachment_alt">Название изображения</label>
                  <input
                    id="attachment_alt"
                    name="attachment_alt"
                    type="text"
                    class="input"
                    v-model="attachment.alt"
                    placeholder="Название изображения"
                  />
                </div>
              </slot>
              <slot v-else>
                <button
                  type="button"
                  class="button button-primary"
                  @click.prevent="showMediaGallery"
                  >
                  Выбрать файл
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <MediaGallery v-if="isMediaGalleryActive" ref="mediaGallery" @select="updateAttachment" @close="closeMediaGallery" />
</template>

<script setup>
import MediaGallery from "@/components/media-gallery";

import { ErrorMessage, Field, Form } from 'vee-validate'
import bannerValidationSchema from '../validation-schema'
import { ref, useTemplateRef, inject } from 'vue'
import { useRoute } from 'vue-router'

const $api = inject('$api')
const $toast = inject('$toast')
const $route = useRoute()

const bannerData = ref(null)
const attachment = ref(null)

const isMediaGalleryActive = ref(false)

const mediaGalleryComponent = useTemplateRef('mediaGallery');

const getBannerData = async () => {
  try {
    const { data: getBannerDataResponse } = await $api.get(
      `/admin/banner/get/${$route.params.id}`,
    )
    bannerData.value = {
      title: getBannerDataResponse.title,
      description: getBannerDataResponse.description,
      button_text: getBannerDataResponse.button_text,
      href: getBannerDataResponse.href,
    }
    attachment.value = {
      id: getBannerDataResponse.attachment.id,
      alt: getBannerDataResponse.attachment.alt,
      file: getBannerDataResponse.attachment.file,
    }
    
    console.log('gg', mediaGalleryComponent.value?.selectedItem)
  } catch (e) {
    console.error(e)
  }
}
getBannerData()

const updateBannerData = async (form) => {
  console.log({
    ...form,
    attachment_id: attachment.value.id,
  })
  try {
    if (!attachment.value?.id) {
      $toast.error('Выберите вложение')
      return
    }
    
    const { data: updateBannerDataResponse } = await $api.put(
      `/admin/banner/update/${$route.params.id}`,
      {
        ...form,
        attachment_id: attachment.value.id,
      },
    )
    console.log(updateBannerDataResponse)
    $toast.success('Баннер обновлен!')
  } catch (e) {
    console.error(e)
  }
}

const updateAttachment = async () => {
  attachment.value = mediaGalleryComponent.value?.selectedItem;
  closeMediaGallery();
}

const closeMediaGallery = () => {
  isMediaGalleryActive.value = false;
}

const showMediaGallery = () => {
  isMediaGalleryActive.value = true;
}

const removeAttachment = () => {
  attachment.value.id = ''
  attachment.value.alt = ''
  attachment.value.file = ''
}
</script>
