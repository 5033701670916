<script setup>
// import { Form, Field, ErrorMessage } from "vee-validate"
// import * as yup from "yup"
// import { setLocale } from "yup"
// import { ru } from "yup-locales"

import { ref } from 'vue'
import { inject } from 'vue'
import {useUserStore} from "@/stores/user";
import {useRouter} from "vue-router";
const $api = inject('$api')
const $toast = inject('$toast')
const $router = useRouter()

const userStorage = useUserStore()

const authData = ref({
  email: null,
  password: null,
  browser: window.navigator.userAgent,
})

const isLoading = ref(false)

const login = async () => {
  if (isLoading.value) return
  isLoading.value = true

  try {
    const { data: loginResponse } = await $api.post('/auth/login', authData.value)
    if (loginResponse) {
      userStorage.user.name = loginResponse.user.name ?? null
      userStorage.user.email = loginResponse.user.email ?? null
      
      userStorage.session.token = loginResponse.session.token
      userStorage.session.browser = loginResponse.session.browser
      
      userStorage.role.slug = loginResponse.role.slug
      userStorage.role.name = loginResponse.role.name
      
      userStorage.isAuthorized = true
      
      // await $createAxiosInstanceGlob()
      
      await $router.push({ name: 'dashboard' })
      return true;
    }
  } catch (e) {
    isLoading.value = false;
    
    if (e.request?.status === 401) {
      return $toast.error('Неверные данные для авторизации')
    } else if(e.request?.status === 429) {
      return $toast.error('Вы превысили количество попыток авторизации. Попробуйте через 5 минут.')
    } else if (typeof e.request === 'undefined' || e.request?.status >= 404) {
      return $toast.error('Непредвиденная ошибка. Попробуйте повторить позднее.')
    } else {
      return $toast.error('Ошибка авторизации. Обратитесь к администратору.')
    }
  }
}
</script>

<style lang="scss">
@import './style.scss';
</style>

<template>
  <div class="login-form">
    <h1 class="heading_4">Авторизация</h1>
    <form class="column" @submit.prevent="login" autocomplete="off">
      <div class="form-group">
        <label for="email">Email</label>
        <input
          id="email"
          type="email"
          v-model="authData.email"
          class="input"
          placeholder="Email"
          required
          autofocus
        />
      </div>
      <div class="form-group">
        <label for="password">Пароль</label>
        <input
          id="password"
          type="password"
          v-model="authData.password"
          class="input"
          placeholder="Пароль"
          required
        />
      </div>
      <button type="submit" class="button button-primary">Войти</button>
    </form>
  </div>
</template>
