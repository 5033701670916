<template>
  <div class="ui-page">
    <div class="ui-page-content">
      <div class="row">
        <div class="col-desktop-4">
          <router-link
            :to="{
              name: 'category.create',
            }"
            class="button button-primary"
          >
            Создать категорию товаров
          </router-link>
        </div>
      </div>

      <br />

      <div class="box">
        <table class="ui-table ui-table--interactive">
          <thead>
            <tr>
              <td width="96">Изображение</td>
              <td>Название категории</td>
              <td>Описание</td>
              <td>Родительская</td>
              <td>Опубликована</td>
            </tr>
          </thead>

          <tbody v-if="categories && categories.length">
            <tr
              v-for="category in categories"
              :key="category.id"
              @click.prevent="$router.push({ name: 'category.edit', params: { id: category.id } })"
            >
              <td>
                <slot v-if="category.attachment">
                  <img
                    src="{{ category.attachment.file }}"
                    alt="category.attachment.alt"
                  />
                </slot>
                <slot v-else>
                  <img src="@/assets/img/no-photo.svg" alt="Нет фото" />
                </slot>
              </td>

              <td>
                <div class="column">
                  <span class="text">{{ category.title }}</span>
                  <span class="caption">{{ category.slug }}</span>
                </div>
              </td>

              <td>
                {{ category.description && category.description.length ? 'Есть' : 'Нет' }}
              </td>
              
              <td>
                <slot v-if="category.parent">
                  <span class="text">{{ category.parent.title }}</span>
                </slot>
                <slot v-else>
                  <span class="text">Корневая</span>
                </slot>
              </td>
              
              <td>
                <span
                  :class="{
                    'text': true,
                    'text-color-success': category.is_published,
                    'text-color-danger': !category.is_published
                  }">
                  {{ category.is_published ? 'Да' : 'Нет' }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue'
import { useRouter } from "vue-router";

const $api = inject('$api')
const $router = useRouter()

const categories = ref([])
const getCategories = async () => {
  try {
    const { data: getCategoriesResponse } = await $api.get(
      '/admin/category/list?with_parent=1',
    )
    categories.value = getCategoriesResponse.data
    console.log(categories.value)
  } catch (e) {
    console.log(e)
  }
}
getCategories()
</script>
