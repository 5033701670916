<template>
  <div class="ui-page">
    <div class="ui-page-content">
      <div class="box">
        <table class="ui-table ui-table--interactive">
          <thead>
            <tr>
              <td>Номер</td>
              <td>Статус</td>
              <td>ФИО</td>
              <td>Сумма</td>
              <td>Источник</td>
              <td>Оплата</td>
              <td>Доставка</td>
              <td>Телефон</td>
              <td>Дата</td>
            </tr>
          </thead>

          <tbody v-if="orders.length">
            <tr
              v-for="(order, index) in orders"
              :key="index"
              @click.prevent="
                $router.push({ name: 'order.edit', params: { id: order.id } })
              "
            >
              <td>#{{ order.id }}</td>
              <td>{{ order.status.internal_name }}</td>
              <td>
                {{
                  `${order.user.contacts.last_name} ${order.user.contacts.first_name} ${order.user.contacts.surname}`
                }}
              </td>
              <td>{{ order.order_price }}</td>
              <td>Пока нет</td>
              <td>{{ order.payment_method.title }}</td>
              <td>{{ order.delivery_type.title }}</td>
              <td>{{ order.user.contacts.phone }}</td>
              <td>{{ moment(order.created_at).format('MM-DD-YYYY hh:mm') }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, inject, onActivated } from 'vue'
import moment from 'moment'

const $toast = inject('$toast')
const $api = inject('$api')

const orders = ref([])
const pageNumber = ref(1)
const perPage = ref(30)

const getOrders = async (page_number, per_page) => {
  try {
    const { data: orderListResponse } = await $api.get(
      `/admin/order/list?per_page=${per_page.value}&page=${page_number.value}`,
    )
    orders.value = orderListResponse.data
  } catch (e) {
    $toast.error('Не удалось получить список заказов')
  }
}

onMounted(() => {
  Promise.all([getOrders(pageNumber, perPage)]).then(() => {
    console.log('orders', orders.value)
  })
})
</script>
