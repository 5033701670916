<template>
  <div class="ui-sidebar">
    <div class="ui-sidebar__header">
      <img src="@/assets/img/molodost-minimal-logo-dark.svg" alt="Molodost">
    </div>
    <div class="ui-sidebar__content">
      <RouterLink :to="{ name: 'dashboard' }">
        <i class="i i-dashboard"></i>
        <span class="p">Панель управления</span>
      </RouterLink>
      <RouterLink :to="{ name: 'order' }">
        <i class="i i-shopping-cart"></i>
        <span class="p">Заказы</span>
      </RouterLink>
      <RouterLink :to="{ name: 'banner' }">
        <i class="i i-photo"></i>
        <span class="p">Баннеры</span>
      </RouterLink>
      <RouterLink :to="{ name: 'category' }">
        <i class="i i-stack-2"></i>
        <span class="p">Категории</span>
      </RouterLink>
      <RouterLink :to="{ name: 'product' }">
        <i class="i i-box"></i>
        <span class="p">Товары</span>
      </RouterLink>
      <RouterLink :to="{ name: 'showcase' }">
        <i class="i i-building-store"></i>
        <span class="p">Витрины</span>
      </RouterLink>
      <RouterLink :to="{ name: 'promo-code' }">
        <i class="i i-discount"></i>
        <span class="p">Промо-коды</span>
      </RouterLink>
      <RouterLink :to="{ name: 'settings' }">
        <i class="i i-settings"></i>
        <span class="p">Настройки</span>
      </RouterLink>
      <a href="#" @click.prevent="userStore.logout" class="logout">
        <i class="i i-logout"></i>
        <span class="p">Выйти</span>
      </a>
    </div>
  </div>
</template>

<script setup>
import { useUserStore } from '@/stores/user'
const userStore = useUserStore()
</script>

<style scoped lang="scss">
@import './style.scss';
</style>
