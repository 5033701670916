<template>
  <div class="home-page">
    <h1>
      Welcome to admin dashboard
    </h1>
    
    <router-link :to="{ name: 'login' }">Login</router-link>
  </div>
</template>

<script setup>
</script>

<style lang="scss">
@import './style.scss';
</style>
