<template>
  <div class="ui-page">
    <div class="ui-page-content" v-if="order">
      <div class="row">
        <div class="col-desktop-6 col-tablet-12 col-mobile-12">
          <div class="box">
            <span class="heading_5 box-heading">Данные клиента</span>

            <div class="column">
              <div class="row">
                <div class="col-desktop-12 col-tablet-6 col-mobile-12">
                  <div class="form-group">
                    <label for="first_name">Имя</label>
                    <input
                      id="first_name"
                      name="first_name"
                      class="input"
                      placeholder="Имя"
                      v-model="order.user.contacts.first_name"
                    />
                  </div>
                </div>
                <div class="col-desktop-12 col-tablet-6 col-mobile-12">
                  <div class="form-group">
                    <label for="first_name">Фамилия</label>
                    <input
                      id="last_name"
                      name="last_name"
                      class="input"
                      placeholder="Фамилия"
                      v-model="order.user.contacts.last_name"
                    />
                  </div>
                </div>
                <div class="col-desktop-12 col-tablet-6 col-mobile-12">
                  <div class="form-group">
                    <label for="surname">Отчество</label>
                    <input
                      id="surname"
                      name="surname"
                      class="input"
                      placeholder="Отчество"
                      v-model="order.user.contacts.surname"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-desktop-12 col-tablet-6 col-mobile-12">
                  <div class="form-group">
                    <label for="phone">Номер телефона</label>
                    <input
                      id="phone"
                      type="text"
                      class="input"
                      placeholder="Номер телефона"
                      v-model="order.user.contacts.phone"
                    />
                  </div>
                </div>
                <div class="col-desktop-12 col-tablet-6 col-mobile-12">
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      id="email"
                      type="email"
                      class="input"
                      placeholder="Email"
                      v-model="order.user.email"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-desktop-6 col-tablet-12 col-mobile-12">
          <div class="box">
            <h5 class="heading_5 box-heading">Доставка</h5>

            <div class="column">
              <div class="row">
                <div class="col-desktop-12 col-tablet-6 col-mobile-12">
                  <div class="form-group">
                    <label for="delivery_type">Способ доставки</label>
                    <select
                      v-if="deliveryTypes && deliveryTypes.length"
                      class="input"
                    >
                      <option
                        v-for="deliveryType in deliveryTypes"
                        :key="deliveryType.id"
                        :selected="order.delivery_type.id === deliveryType.id"
                      >
                        {{ deliveryType.title }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-desktop-12 col-tablet-6 col-mobile-12">
                  <div class="form-group">
                    <label for="index">Индекс</label>
                    <input
                      id="index"
                      name="index"
                      class="input"
                      placeholder="Индекс"
                      v-model="order.user.contacts.index"
                    />
                  </div>
                </div>
                <div class="col-desktop-12 col-tablet-6 col-mobile-12">
                  <div class="form-group">
                    <label for="city">Город</label>
                    <input
                      id="city"
                      name="city"
                      class="input"
                      placeholder="Город"
                      v-model="order.user.contacts.city"
                    />
                  </div>
                </div>
                <div class="col-desktop-12 col-tablet-6 col-mobile-12">
                  <div class="form-group">
                    <label for="street">Улица</label>
                    <input
                      id="street"
                      name="street"
                      class="input"
                      placeholder="Улица"
                      v-model="order.user.contacts.street"
                    />
                  </div>
                </div>
                <div class="col-desktop-4 col-tablet-4 col-mobile-12">
                  <div class="form-group">
                    <label for="house">Дом</label>
                    <input
                      id="house"
                      name="house"
                      class="input"
                      placeholder="Дом"
                      v-model="order.user.contacts.house"
                    />
                  </div>
                </div>
                <div class="col-desktop-4 col-tablet-4 col-mobile-12">
                  <div class="form-group">
                    <label for="frame">Корпус</label>
                    <input
                      id="frame"
                      name="frame"
                      class="input"
                      placeholder="Корпус"
                      v-model="order.user.contacts.frame"
                    />
                  </div>
                </div>
                <div class="col-desktop-4 col-tablet-4 col-mobile-12">
                  <div class="form-group">
                    <label for="apartment">Номер квартиры</label>
                    <input
                      id="apartment"
                      name="apartment"
                      class="input"
                      placeholder="Квартира"
                      v-model="order.user.contacts.apartment"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="box">
        <h2 class="heading_5 box-heading">Состав заказа</h2>

        <table class="ui-table">
          <thead>
            <tr>
              <td>Изображение</td>
              <td>Название</td>
              <td>Цена</td>
              <td>Количество</td>
              <td>Артикул</td>
            </tr>
          </thead>

          <tbody v-if="order.items && order.items.length">
            <tr v-for="(item, index) in order.items" :key="index">
              <td
                v-if="
                  item.product.attachments && item.product.attachments.length
                "
              >
                <img
                  :src="item.product.attachments[0].file"
                  :alt="item.product.name"
                  class="order-edit-page__item-thumbnail"
                />
              </td>
              <td>
                {{ item.product.name }}
              </td>
              <td>
                {{
                  item.product.prices.discount
                    ? item.product.prices.discount
                    : item.product.prices.regular
                }}
              </td>
              <td>
                {{ item.quantity }}
              </td>
              <td>
                {{ item.variant.article_number }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from 'vue'
import { useRoute } from 'vue-router'

const $api = inject('$api')
const order = ref(null)
const route = useRoute()

const getOrder = async () => {
  try {
    const { data: orderResponse } = await $api.get(
      `/admin/order/get/${route.params.id}`,
    )
    order.value = orderResponse
    console.log(order.value)
  } catch (error) {
    console.log(error)
  }
}
getOrder()

const deliveryTypes = ref([])
const getDeliveryTypes = async () => {
  try {
    const { data: deliveryResponse } = await $api.get('/delivery-type/list')
    console.log(deliveryResponse)
    deliveryTypes.value = deliveryResponse.data
  } catch (e) {
    console.log(e)
  }
}
getDeliveryTypes()
</script>

<style lang="scss" scoped>
@import './style';
</style>
