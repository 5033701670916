<template>
  <div class="ui-page">
    <div class="ui-page-content">
      <div class="row">
        <div class="col-desktop-8">
          <div class="box" v-if="showcase">
            <Form
              :validation-schema="showcaseValidationSchema"
              :initial-values="showcase"
              v-slot="{ errors, values }"
              @submit="updateShowcase"
            >
              <h1 class="heading_5 box-heading">Редактирование витрины</h1>

              <div class="column">
                <div class="form-group">
                  <label for="name">Название витрины</label>
                  <Field
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Название витрины"
                    :class="{
                      input: true,
                      'is-invalid': errors['name'],
                    }"
                  />
                  <ErrorMessage name="name" class="error" />
                </div>

                <div class="form-group">
                  <label for="slug">Slug</label>
                  <Field
                    id="slug"
                    name="slug"
                    type="text"
                    placeholder="Slug"
                    :class="{
                      input: true,
                      'is-invalid': errors['slug'],
                    }"
                  />
                  <ErrorMessage name="slug" class="error" />
                </div>

                <div class="form-group">
                  <label for="products">Добавить продукты</label>
                  
                  <div class="form-group">
                    <select
                      class="input"
                      @change.prevent="addProduct"
                    >
                      <option
                        v-for="product in products"
                        :key="product.id"
                        :value="product.id"
                        >
                        {{ product.name}} - вариаций {{ product.variants.length ?? 0 }}
                      </option>
                    </select>
                  </div>
                  
                  <table class="ui-table">
                    <thead>
                      <td>Изображение</td>
                      <td>Название</td>
                      <td>Вариации</td>
                      <td></td>
                    </thead>
                    <tbody v-if="selectedProducts">
                      <tr
                        v-for="(product, index) in selectedProducts"
                        :key="index"
                      >
                        <td width="96">
                          <slot v-if="product.attachments && product.attachments.length">
                            <img
                              :src="product.attachments[0].file"
                              :alt="product.attachments[0].alt"
                            />
                          </slot>
                          <slot v-else>
                            <img
                              src="@/assets/img/no-photo.svg"
                              alt="Нет фото"
                            />
                          </slot>
                        </td>
                        <td>
                          {{ product.name }}
                        </td>
                        <td>
                          <slot v-if="product.variants && product.variants.length">
                            {{ product.variants.length }} вариантов
                          </slot>
                          <slot v-else>
                            0 вариантов
                          </slot>
                        </td>
                        <td>
                          <button class="button button-primary" @click.prevent="removeProduct(product.id)">
                            Удалить
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <td colspan="3">
                        Вы не выбрали ни одного продукта
                      </td>
                    </tbody>
                  </table>
                </div>
                
                <button class="button button-primary" type="submit">Сохранить</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// validation
import { Form, Field, ErrorMessage } from 'vee-validate'
import showcaseValidationSchema from '../validation-schema'

import { ref, inject } from 'vue'
import { useRoute } from 'vue-router'

const $route = useRoute()
const $api = inject('$api')
const $toast = inject('$toast')

const showcase = ref(null)
const selectedProducts = ref([])
const getShowcase = async () => {
  try {
    const { data: getShowcaseResponse } = await $api.get(
      `/admin/showcase/get/${$route.params.id}`,
    )
    showcase.value = {
      name: getShowcaseResponse.name,
      slug: getShowcaseResponse.slug,
      products: []
    }
    
    selectedProducts.value = getShowcaseResponse.products
  } catch (e) {
    console.log(e)
  }
}
getShowcase()

const products = ref([])
const getProducts = async () => {
  try {
    const { data: getProductsResponse } = await $api.get('/admin/product/list')
    products.value = getProductsResponse.data
  } catch (e) {
    console.log(e)
  }
}
getProducts()

const addProduct = async (e) => selectedProducts.value.push(products.value.find(item => item.id == e.target.value))
const removeProduct = async (id) => {
  selectedProducts.value.splice(selectedProducts.value.findIndex(item => item.id === id), 1)
}

const updateShowcase = async (formData) => {
  try {
    formData.products = selectedProducts.value
    const {data: updateShowcaseResponse} = await $api.put(`/admin/showcase/update/${$route.params.id}`, formData)
    
    showcase.value = {
      name: updateShowcaseResponse.name,
      slug: updateShowcaseResponse.slug,
      products: []
    }
    
    selectedProducts.value = updateShowcaseResponse.products
    $toast.success('Витрина обновлена!')
  } catch (e) {
    console.log(e)
  }
}
</script>
