import vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'

// Layouts
import AuthLayout from '@/layouts/auth'
import DashboardLayout from '@/layouts/dashboard'

// Service pages
import HomePage from '@/pages/home'
import LoginPage from '@/pages/auth/login'

// Dashboard pages
import OrderListPage from '@/pages/order/list'
import OrderCreatePage from '@/pages/order/create'
import OrderEditPage from '@/pages/order/edit'

import BannerListPage from '@/pages/banner/banner/list'
import BannerCreatePage from '@/pages/banner/banner/create'
import BannerEditPage from '@/pages/banner/banner/edit'

import BannerGroupListPage from '@/pages/banner/group/list'
import BannerGroupCreatePage from '@/pages/banner/group/create'
import BannerGroupEditPage from '@/pages/banner/group/edit'

import CategoryListPage from '@/pages/category/list'
import CategoryCreatePage from '@/pages/category/create'
import CategoryEditPage from '@/pages/category/edit'

import ProductListPage from '@/pages/product/list'
import ProductCreatePage from '@/pages/product/create'
import ProductEditPage from '@/pages/product/edit'

import ShowcaseListPage from '@/pages/showcase/list'
import ShowcaseCreatePage from '@/pages/showcase/create'
import ShowcaseEditPage from '@/pages/showcase/edit'

import PromocodeListPage from '@/pages/promocode/list'
import PromocodeCreatePage from '@/pages/promocode/create'
import PromocodeEditPage from '@/pages/promocode/edit'

import SettingsMoyskladPage from '@/pages/setting/moysklad_settings'

const DEFAULT_TITLE = 'Панель управления'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage,
      meta: {
        title: 'Главная',
        auth: false,
        roles: ['guest'],
      },
    },
    {
      path: '/auth',
      name: 'auth',
      component: AuthLayout,
      children: [
        {
          path: 'login',
          name: 'login',
          component: LoginPage,
          meta: {
            title: 'Логин',
            auth: false,
            roles: ['guest'],
          },
        },
      ],
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardLayout,
      meta: {
        auth: true,
        title: 'Дашборд'
      },
      children: [
        {
          path: 'order',
          name: 'order',
          meta: {
            auth: true,
            title: 'Заказы'
          },
          redirect: { name: 'order.list' },
          children: [
            {
              path: 'list',
              name: 'order.list',
              component: OrderListPage,
              meta: {
                auth: true,
                title: 'Список заказов',
                roles: ['manager', 'admin', 'owner'],
              },
            },
            {
              path: 'create',
              name: 'order.create',
              component: OrderCreatePage,
              meta: {
                auth: true,
                title: 'Создание заказа',
                roles: ['manager', 'admin', 'owner'],
              },
            },
            {
              path: 'edit/:id',
              name: 'order.edit',
              component: OrderEditPage,
              meta: {
                auth: true,
                title: 'Редактирование заказа',
                roles: ['manager', 'admin', 'owner'],
              },
            },
          ],
        },
        {
          path: 'banner',
          name: 'banner',
          meta: {
            auth: true,
            title: 'Баннеры',
            roles: ['admin', 'owner'],
          },
          redirect: { name: 'banner.group' },
          children: [
            {
              path: 'banner',
              name: 'banner.banner',
              redirect: { name: 'banner.banner.list' },
              meta: {
                auth: true,
                title: 'Баннеры',
                roles: ['admin', 'owner'],
              },
              children: [
                {
                  path: 'list/:bannerGroupId',
                  name: 'banner.banner.list',
                  component: BannerListPage,
                  meta: {
                    auth: true,
                    title: 'Список баннеров',
                    roles: ['admin', 'owner'],
                  },
                },
                {
                  path: 'create/:bannerGroupId',
                  name: 'banner.banner.create',
                  component: BannerCreatePage,
                  meta: {
                    auth: true,
                    title: 'Создание баннера',
                    roles: ['admin', 'owner'],
                  },
                },
                {
                  path: 'edit/:id',
                  name: 'banner.banner.edit',
                  component: BannerEditPage,
                  meta: {
                    auth: true,
                    title: 'Редактирование баннера',
                    roles: ['admin', 'owner'],
                  },
                },
              ]
            },
            {
              path: 'group',
              name: 'banner.group',
              redirect: { name: 'banner.group.list' },
              meta: {
                auth: true,
                title: 'Группы баннеров',
                roles: ['admin', 'owner'],
              },
              children: [
                {
                  path: 'list',
                  name: 'banner.group.list',
                  component: BannerGroupListPage,
                  meta: {
                    auth: true,
                    title: 'Список групп баннеров',
                    roles: ['admin', 'owner'],
                  },
                },
                {
                  path: 'create',
                  name: 'banner.group.create',
                  component: BannerGroupCreatePage,
                  meta: {
                    auth: true,
                    title: 'Создание группы баннеров',
                    roles: ['admin', 'owner'],
                  },
                },
                {
                  path: 'edit/:id',
                  name: 'banner.group.edit',
                  component: BannerGroupEditPage,
                  meta: {
                    auth: true,
                    title: 'Редактирование группы баннеров',
                    roles: ['admin', 'owner'],
                  },
                },
              ]
            },

          ],
        },
        // shop routes start
        {
          path: 'promo-code',
          name: 'promo-code',
          redirect: {name: 'promo-code.list'},
          meta: {
            auth: true,
            title: 'Промо-коды',
            roles: ['admin', 'owner'],
          },
          children: [
            {
              path: 'list',
              name: 'promo-code.list',
              component: PromocodeListPage,
              meta: {
                auth: true,
                title: 'Список промо-кодов',
                roles: ['admin', 'owner'],
              },
            },
            {
              path: 'create',
              name: 'promo-code.create',
              component: PromocodeCreatePage,
              meta: {
                auth: true,
                title: 'Создать промо-код',
                roles: ['admin', 'owner'],
              },
            },
            {
              path: 'edit/:id',
              name: 'promo-code.edit',
              component: PromocodeEditPage,
              meta: {
                auth: true,
                title: 'Редактирование промо-кода',
                roles: ['admin', 'owner'],
              },
            }
          ]
        },
        {
          path: 'showcase',
          name: 'showcase',
          redirect: { name: 'showcase.list' },
          meta: {
            auth: true,
            title: 'Витрины',
            roles: ['admin', 'owner'],
          },
          children: [
            {
              path: 'list',
              name: 'showcase.list',
              component: ShowcaseListPage,
              meta: {
                auth: true,
                title: 'Список витрин',
                roles: ['admin', 'owner'],
              },
            },
            {
              path: 'create',
              name: 'showcase.create',
              component: ShowcaseCreatePage,
              meta: {
                auth: true,
                title: 'Создать витрину',
                roles: ['admin', 'owner'],
              },
            },
            {
              path: 'edit/:id',
              name: 'showcase.edit',
              component: ShowcaseEditPage,
              meta: {
                auth: true,
                title: 'Редактировать витрину',
                roles: ['admin', 'owner'],
              },
            }
          ]
        },
        {
          path: 'category',
          name: 'category',
          meta: {
            auth: true,
            title: 'Категории',
            roles: ['manager', 'admin', 'owner'],
          },
          redirect: { name: 'category.list' },
          children: [
            {
              path: 'list',
              name: 'category.list',
              component: CategoryListPage,
              meta: {
                auth: true,
                title: 'Список категорий',
                roles: ['manager', 'admin', 'owner'],
              },
            },
            {
              path: 'create',
              name: 'category.create',
              component: CategoryCreatePage,
              meta: {
                auth: true,
                title: 'Создание категории',
                roles: ['manager', 'admin', 'owner'],
              },
            },
            {
              path: 'edit/:id',
              name: 'category.edit',
              component: CategoryEditPage,
              meta: {
                auth: true,
                title: 'Редактирование категории',
                roles: ['manager', 'admin', 'owner'],
              },
            },
          ],
        },
        {
          path: 'product',
          name: 'product',
          meta: {
            auth: true,
            title: 'Товары',
            roles: ['manager', 'admin', 'owner'],
          },
          redirect: { name: 'product.list' },
          children: [
            {
              path: 'list',
              name: 'product.list',
              component: ProductListPage,
              meta: {
                auth: true,
                title: 'Список товаров',
                roles: ['manager', 'admin', 'owner'],
              },
            },
            {
              path: 'create',
              name: 'product.create',
              component: ProductCreatePage,
              meta: {
                auth: true,
                title: 'Создание товара',
                roles: ['manager', 'admin', 'owner'],
              },
            },
            {
              path: 'edit/:id',
              name: 'product.edit',
              component: ProductEditPage,
              meta: {
                auth: true,
                title: 'Редактирование товара',
                roles: ['manager', 'admin', 'owner'],
              },
            },
          ],
        },
        {
          path: 'settings',
          name: 'settings',
          meta: {
            auth: true,
            title: 'Настройки магазина',
            roles: ['admin', 'owner'],
          },
          children: [
            {
              path: 'moysklad',
              name: 'settings.moysklad',
              component: SettingsMoyskladPage,
              meta: {
                auth: true,
                title: 'Мой склад',
                roles: ['admin', 'owner'],
              },
            },
          ],
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  if (to.meta.auth && !userStore.isAuthorized) {
    return { name: 'login' }
  }

  document.title = to.meta.title || DEFAULT_TITLE;
  next();
})

export default router
