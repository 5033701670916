import * as yup from "yup";
import { setLocale } from 'yup'
import { ru } from 'yup-locales'
setLocale(ru)

const showcaseValidationSchema = yup.object({
    name: yup.string().max(511).required(),
    slug: yup.string().max(511).required(),
    products: yup.array().of(yup.number()),
})

export default showcaseValidationSchema;
