import * as yup from "yup";
import { setLocale } from 'yup'
import { ru } from 'yup-locales'
setLocale(ru)

const bannerGroupValidationSchema = yup.object({
    name: yup.string().max(511).required(),
    description: yup.string().max(1024).required(),
    slug: yup.string().max(511).required(),
})

export default bannerGroupValidationSchema;
