<template>
  <div class="ui-page">
    <div class="ui-page-content">
      <div class="row">
        <div class="col-desktop-8">
          <div class="box">
            <h1 class="heading_5 box-heading">Редактирование продукта</h1>

            <div class="column">
              <div class="form-group">
                <label for="name">Название продукта</label>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from 'vue'

const $api = inject('$api')

const product = ref(null)
const getProduct = async () => {
  try {
    const { data: getProductResponse } = await $api.get()
  } catch (e) {
    console.log(e)
  }
}
</script>
