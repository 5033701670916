<template>
  <div class="auth-layout">
    <RouterView/>
  </div>
</template>

<script setup>
</script>

<style lang="scss">
@import './style.scss';
</style>
